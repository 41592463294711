import type { Analyst } from "@core/api/analysts/model/Analyst";
import type { SearchType } from "@core/common/components/search-bar/types";
import { getFullName } from "@core/common/utils/getFullName";
import { routes } from "@core/routes/routes";

export const filterByIntrests = (data: Analyst[], intrests: SearchType[]) => {
  if (intrests.length === 0) {
    return data;
  }
  return data.filter((analyst) =>
    analyst.intrests?.find((analystIntrest) =>
      intrests.find((intrest) => intrest.id === analystIntrest.id)
    )
  );
};

export const filterByCompanies = (data: Analyst[], companies: SearchType[]) => {
  if (companies.length === 0) {
    return data;
  }
  return data.filter((analyst) =>
    analyst.companies.find((analystCompany) =>
      companies.find((company) => company.id === analystCompany.id)
    )
  );
};

export const filterByAnalyst = (data: Analyst[], analysts: SearchType[]) => {
  if (analysts.length === 0) {
    return data;
  }
  return data.filter(
    (analystData) =>
      analysts.find((analyst) =>
        getFullName(analystData.firstname, analystData.lastname, false)
          .toLowerCase()
          .includes(analyst.value.toLowerCase())
      ) != undefined
  );
};

export const analystURLBuilder = (searchParams?: SearchType[]): string => {
  if (searchParams === undefined) {
    return routes.analysts.pathname;
  }
  return `${routes.analysts.pathname}?search=${encodeURIComponent(JSON.stringify(searchParams))}`;
};
