import Head from "next/head";
import type { GetStaticProps } from "@lib/next/getStaticProps";
import { serverSideTranslations } from "@core/i18n/serverSideTranslations";
import { RootLayout } from "@core/common/layouts/RootLayout/RootLayout";
import { HomeScreen } from "@domains/home/HomeScreen";
import { homeNamespaces } from "@domains/home/homeNamespaces";
import { getIntrests } from "@core/api/intrests/getIntrests";
import type { Intrest } from "@core/api/intrests/model/Intrest";
import { useTranslation } from "@core/i18n/useTranslation";
import { getEditorialNotes } from "@core/api/editorial-notes/getEditorialNotes";
import type { EditorialNote } from "@core/api/editorial-notes/model/EditorialNote";

export const getStaticProps: GetStaticProps = async ({ locale, defaultLocale }) => {
  const interestsPromise = getIntrests();
  const editorialNotesPromise = getEditorialNotes();
  const [interests, editorialNotes, translations] = await Promise.all([
    interestsPromise,
    editorialNotesPromise,
    serverSideTranslations(locale ?? defaultLocale, homeNamespaces),
  ]);

  return {
    props: {
      themes: interests,
      editorialNotes,
      ...translations,
    },
    revalidate: 5,
  };
};

type HomeProps = {
  themes: Intrest[];
  editorialNotes: EditorialNote[];
};

export default function Home({ themes, editorialNotes }: HomeProps) {
  const { t } = useTranslation(homeNamespaces);

  return (
    <RootLayout className={"font-sans text-grey-400 flex flex-col min-h-screen"}>
      <Head>
        <title>{t("home:pageTitle")}</title>
        <meta name="description" content={t("home:pageDescription")} />
      </Head>

      <HomeScreen themes={themes} editorialNotes={editorialNotes} />
    </RootLayout>
  );
}
