import cx from "classnames";
import { Button } from "@ui-kit/components/buttons/Button";
import { Link } from "@ui-kit/components/link/Link";
import styles from "./CtaSection.module.css";
import type { ComponentPropsWithoutRef } from "react";
import type { UrlObject } from "url";
import Image from "next/image";
import { useResponsive } from "@core/common/hooks/useResponsive";

const imageSources = {
  academics: {
    mobile: "/img/home/home_academics_mobile.jpg",
    desktop: "/img/home/home_academics_desktop.jpg",
  },
  analysts: {
    mobile: "/img/home/home_analysts_mobile.jpg",
    desktop: "/img/home/home_analysts_desktop.jpg",
  },
  forum: { mobile: "/img/home/home_forum_mobile.jpg", desktop: "/img/home/home_forum_desktop.jpg" },
  investors: {
    mobile: "/img/home/home_investors_mobile.jpg",
    desktop: "/img/home/home_investors_desktop.jpg",
  },
  "qalis-about-us": {
    mobile: "/img/qalis/qalis-about-us-mobile.jpg",
    desktop: "/img/qalis/qalis-about-us.jpg",
  },
  qalis: {
    mobile: "/img/qalis/qalis-meet-an-expert-mobile.jpg",
    desktop: "/img/qalis/qalis-meet-an-expert.jpg",
  },
  "qalis-home": {
    mobile: "/img/home/home_qalis.jpg",
    desktop: "/img/home/home_qalis.jpg",
  },
};

export type SectionCtaProps = {
  backgroundImage?: keyof typeof imageSources;
  videoFile?: string;
  videoClassname?: keyof Omit<typeof styles, "locals">;
  title: string;
  text: string;
  textFirst?: boolean;
  ctaButton?: {
    href?: string | UrlObject;
    onClick?: () => void;
    text: string;
    light?: boolean;
  };
} & ComponentPropsWithoutRef<"section">;

export const CtaSection = ({
  backgroundImage,
  videoFile,
  videoClassname,
  title,
  text,
  textFirst,
  className,
  ctaButton,
}: SectionCtaProps) => {
  const { isMobileSize } = useResponsive();

  return (
    <section className={cx(className, "flex flex-col xl:flex-row items-stretch")}>
      {backgroundImage !== undefined && (
        <div
          className={cx(styles.image, "relative flex-grow", {
            "xl:order-1": textFirst,
          })}
        >
          <Image
            lazyBoundary={"1000px"}
            src={
              isMobileSize
                ? imageSources[backgroundImage].mobile
                : imageSources[backgroundImage].desktop
            }
            alt={title}
            layout={"fill"}
            objectFit={"cover"}
          />
        </div>
      )}
      {videoFile !== undefined && (
        <video
          autoPlay
          muted
          loop
          playsInline
          disableRemotePlayback
          className={cx(videoClassname !== undefined && styles[videoClassname], "flex-grow", {
            "xl:order-1": textFirst,
          })}
        >
          <source src={videoFile} />
        </video>
      )}
      <div className={cx(styles.text, "flex flex-col bg-white my-auto")}>
        <h2 className={"heading2 text-primary-400 text-grey-400"}>{title}</h2>
        <p className={"bodyText font-light whitespace-pre-line text-grey-400"}>{text}</p>
        {ctaButton !== undefined && (
          <>
            {ctaButton.href !== undefined ? (
              <Link href={ctaButton.href}>
                <Button
                  as="span"
                  textColor={ctaButton.light === true ? "primary-400" : "white"}
                  bgColor={ctaButton.light === true ? "white" : "primary-400"}
                  outlined={ctaButton.light}
                  disabled={ctaButton.href === ""}
                >
                  {ctaButton.text}
                </Button>
              </Link>
            ) : (
              ctaButton.onClick !== undefined && (
                <Button
                  onClick={(e) => {
                    if (ctaButton.onClick !== undefined) {
                      e.preventDefault();
                      ctaButton.onClick();
                    }
                  }}
                  textColor={ctaButton.light === true ? "primary-400" : "white"}
                  bgColor={ctaButton.light === true ? "white" : "primary-400"}
                  outlined={ctaButton.light}
                >
                  {ctaButton.text}
                </Button>
              )
            )}
          </>
        )}
      </div>
    </section>
  );
};
