import { Button } from "@ui-kit/components/buttons/Button";
import { Link } from "@ui-kit/components/link/Link";
import cx from "classnames";
import styles from "./ThemeSection.module.css";
import * as _ from "lodash";
import type { Intrest } from "@core/api/intrests/model/Intrest";
import { analystURLBuilder } from "@domains/analysts/utils";
import { useTranslation } from "@core/i18n/useTranslation";
import { homeNamespaces } from "@domains/home/homeNamespaces";
import type { SearchType } from "@core/common/components/search-bar/types";

type ThemesSectionProps = { themes: Intrest[] };

export const ThemesSection = ({ themes }: ThemesSectionProps) => {
  const { t } = useTranslation(homeNamespaces);

  const chunkedThemes = _.chunk(_.shuffle(themes), Math.ceil(themes.length / 3));
  const parentThemes = themes.filter((theme) => theme.parent_intrest === null);

  return (
    <section
      className={
        "px-4 xl:px-0 py-8 xl:py-20 gap-6 xl:gap-y-11 flex-col-center overflow-hidden text-grey-400"
      }
    >
      <h2 className={"heading2 text-center"}>{t("home:interests:title")}</h2>
      <nav className={cx("hidden xl:block w-full")}>
        <ul className={cx(styles.marqueesContainer, "flex flex-col gap-y-8 heading1 font-light")}>
          <li>
            <ul className={cx(styles.marquee, styles.animated)}>
              {themes.length > 0 &&
                chunkedThemes[0] &&
                [...chunkedThemes[0], ...chunkedThemes[0]].map(({ id, name }, index) => {
                  const searchParams = [{ id, value: name, type: "intrest" }] as SearchType[];
                  return (
                    <li key={index} className={cx(styles.marqueeTheme)}>
                      <Link href={analystURLBuilder(searchParams)}>{name}</Link>
                    </li>
                  );
                })}
            </ul>
          </li>
          <li>
            <ul className={cx(styles.marquee, styles.animatedReverse)}>
              {themes.length > 0 &&
                chunkedThemes[1] &&
                [...chunkedThemes[1], ...chunkedThemes[1]].map(({ id, name }, index) => {
                  const searchParams = [{ id, value: name, type: "intrest" }] as SearchType[];
                  return (
                    <li key={index} className={cx(styles.marqueeTheme)}>
                      <Link href={analystURLBuilder(searchParams)}>{name}</Link>
                    </li>
                  );
                })}
            </ul>
          </li>
          <li>
            <ul className={cx(styles.marquee, styles.animated)}>
              {themes.length > 0 &&
                chunkedThemes[2] &&
                [...chunkedThemes[2], ...chunkedThemes[2]].map(({ id, name }, index) => {
                  const searchParams = [{ id, value: name, type: "intrest" }] as SearchType[];
                  return (
                    <li key={index} className={cx(styles.marqueeTheme)}>
                      <Link href={analystURLBuilder(searchParams)}>{name}</Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        </ul>
      </nav>
      <nav className={"w-full whitespace-nowrap flex xl:hidden flex-col font-light"}>
        <ul className={cx(styles.listTextEllipsis, "heading3 gap-y-6 flex flex-col text-center")}>
          {parentThemes.map(({ name, id }) => {
            const searchParams = [{ id, value: name, type: "intrest" }] as SearchType[];
            return (
              <li key={id}>
                <Link href={analystURLBuilder(searchParams)}>{name}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <Link href={analystURLBuilder()}>
        <Button textColor={"primary-400"} bgColor={"white"} outlined>
          {t("home:interests:button")}
        </Button>
      </Link>
    </section>
  );
};
