import cx from "classnames";
import styles from "./HeroSection.module.css";

type HeroSectionProps = {
  title: string;
  description: string;
  description2?: string;
  description3?: string;
};

export const HeroSection = ({
  title,
  description,
  description2,
  description3,
}: HeroSectionProps) => (
  <section className={cx(styles.hero, "w-full flex-col-center text-white")}>
    <h1 className="heading4 mb-4">{title}</h1>
    <h2 className={cx(styles.heroSentence, "heading2 xl:heading1 text-center")}>{description}</h2>
    {description2 !== undefined && (
      <h2 className={cx(styles.heroSentence, "text-xl  xl:text-2xl text-center")}>
        {description2}
      </h2>
    )}
    {description3 !== undefined && (
      <h2 className={cx(styles.heroSentence, "heading2 xl:heading1 text-center")}>
        {description3}
      </h2>
    )}
  </section>
);
