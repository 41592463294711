import cx from "classnames";
import styles from "./JoinAdventureBanner.module.css";
import { Button } from "@ui-kit/components/buttons/Button";
import { Link } from "@ui-kit/components/link/Link";
import { useTranslation } from "@core/i18n/useTranslation";
import { routes } from "@core/routes/routes";

export const JoinAdventureBanner = () => {
  const { t } = useTranslation("common");
  return (
    <section
      className={cx(
        styles.section,
        "flex flex-col xl:flex-row px-4 xl:px-20 py-8 xl:py-20 gap-y-8 bg-primary-400 text-white"
      )}
    >
      <h2 className={"heading2 xl:w-1/3 xl:pr-16"}>{t("join-adventure.title")}</h2>
      <div className={"flex flex-col gap-4 xl:pr-16"}>
        <p className={"bodyText font-light"}>{t("join-adventure.investor.description")}</p>
        <Link href={{ pathname: routes.signUp.pathname, query: { accountType: "investor" } }}>
          <Button textColor={"primary-400"} bgColor={"white"}>
            {t("join-adventure.investor.button")}
          </Button>
        </Link>
      </div>

      <div className={"flex flex-col gap-4"}>
        <p className={"bodyText font-light"}>{t("join-adventure.analyst.description")}</p>
        <Link href={routes.signUp.pathname}>
          <Button textColor={"primary-400"} bgColor={"white"}>
            {t("join-adventure.analyst.button")}
          </Button>
        </Link>
      </div>
    </section>
  );
};
