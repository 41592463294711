import { PageLayout } from "@core/common/layouts/PageLayout/PageLayout";
// import { CustomersSection } from "@domains/home/CustomersSection";
import { ThemesSection } from "@domains/home/ThemesSection";
import { EditorialNotesSection } from "@domains/home/EditorialNotesSection";
import { JoinAdventureBanner } from "@core/common/components/join-adventure-banner/JoinAdventureBanner";
import { CtaSection } from "@core/common/components/cta-section/CtaSection";
import { HeroSection } from "@core/common/components/hero-section/HeroSection";
import type { Intrest } from "@core/api/intrests/model/Intrest";
import { useTranslation } from "@core/i18n/useTranslation";
import { homeNamespaces } from "@domains/home/homeNamespaces";
import type { EditorialNote } from "@core/api/editorial-notes/model/EditorialNote";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useNotification } from "@lib/notification/useNotification";
import { routes } from "@core/routes/routes";
import { useUser } from "@core/common/hooks/useUser";
import { isAnalyst } from "@core/api/authentication/utils";

type HomeScreenProps = {
  themes: Intrest[];
  editorialNotes: EditorialNote[];
};

export const HomeScreen = ({ themes, editorialNotes }: HomeScreenProps) => {
  const { t } = useTranslation(homeNamespaces);
  const { user } = useUser();
  const { isReady, query, replace } = useRouter();
  const { notify } = useNotification();

  useEffect(() => {
    const { success } = query;
    const { ["callBackMessage"]: callBackMessage } = query;

    if (success === "forgotPassword") {
      notify(t("home:notifications.forgotPassword"), "success");
      void replace(routes.home.pathname, undefined, { shallow: true });
    }
    if (callBackMessage !== undefined && typeof callBackMessage === "string") {
      notify(callBackMessage, "success", true);
      void replace(routes.home.pathname, undefined, { shallow: true });
    }
  }, [isReady, query, notify, replace, t]);

  return (
    <PageLayout className={"bg-grey-100"}>
      <HeroSection
        title={t("home:hero.title")}
        description={t("home:hero.description")}
        description2={t("home:hero.description2")}
        description3={t("home:hero.description3")}
      />

      <CtaSection
        className={"pt-4"}
        videoFile={"/media/home_strategy.mp4"}
        videoClassname="videoStrategy"
        title={t("home:ourStrategy.title")}
        text={t("home:ourStrategy.description")}
        ctaButton={{
          text: t("home:ourStrategy.button"),
          href: routes.aboutUs.pathname,
          light: true,
        }}
        textFirst
      />
      <CtaSection
        className={"xl:pt-4"}
        backgroundImage={"investors"}
        title={t("home:investors.title")}
        text={t("home:investors.description")}
        {...(user === undefined
          ? {
              ctaButton: {
                text: t("home:investors.button"),
                href: {
                  pathname: routes.signUp.pathname,
                  query: { accountType: "investor" },
                },
              },
            }
          : !isAnalyst(user) && {
              ctaButton: {
                text: t("home:viewMyDashboard"),
                href: { pathname: routes.dashboard.pathname },
              },
            })}
      />
      {/* AO-589: hide "They already trust us" section for now
            <CustomersSection />
          */}
      <CtaSection
        backgroundImage={"analysts"}
        title={t("home:analysts.title")}
        text={t("home:analysts.description")}
        {...(user === undefined
          ? {
              ctaButton: {
                text: t("home:analysts.button"),
                href: { pathname: routes.signUp.pathname, query: { accountType: "analyst" } },
              },
            }
          : isAnalyst(user) && {
              ctaButton: {
                text: t("home:viewMyDashboard"),
                href: { pathname: routes.dashboard.pathname },
              },
            })}
        textFirst
        className="xl:pt-4"
      />
      <ThemesSection themes={themes} />
      <EditorialNotesSection editorialNotes={editorialNotes} />
      <CtaSection
        className={"xl:pt-4"}
        backgroundImage={"academics"}
        title={t("home:academics.title")}
        text={t("home:academics.description")}
        ctaButton={{
          text: t("home:academics.button"),
          href: routes.academicNotes.pathname,
          light: false,
        }}
      />
      <CtaSection
        className={"xl:pt-4"}
        backgroundImage={"qalis-home"}
        title={t("home:qalis.title")}
        text={t("home:qalis.description")}
        ctaButton={{
          text: t("home:qalis.button"),

          href: { pathname: routes.qalis.pathname },
        }}
        textFirst
      />
      <CtaSection
        className={"xl:py-4"}
        backgroundImage={"forum"}
        title={t("home:newsfeed.title")}
        text={t("home:newsfeed.description")}
        ctaButton={{
          text: t("home:newsfeed.button"),
          href: routes.newsfeed.pathname,
          light: true,
        }}
      />
      {user === undefined && <JoinAdventureBanner />}
    </PageLayout>
  );
};
