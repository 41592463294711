import { Button } from "@ui-kit/components/buttons/Button";
import { ReactComponent as ChevronRightIcon } from "@ui-kit/components/icons/svg/Chevron Right.svg";
import cx from "classnames";
import styles from "./EditorialNotesSection.module.css";
import { useTranslation } from "@core/i18n/useTranslation";
import { homeNamespaces } from "@domains/home/homeNamespaces";
import type { EditorialNote } from "@core/api/editorial-notes/model/EditorialNote";
import { EditorialNoteCard } from "@core/common/components/notes/EditorialNoteCard";
import { routes } from "@core/routes/routes";

type EditorialNotesSectionProps = {
  editorialNotes: EditorialNote[];
  isOnNewsfeed?: boolean;
};

export const EditorialNotesSection = ({
  editorialNotes,
  isOnNewsfeed = false,
}: EditorialNotesSectionProps) => {
  const { t } = useTranslation(homeNamespaces);

  return (
    <section
      className={cx(
        styles.section,
        "px-4 xl:px-20 py-8",
        isOnNewsfeed ? "xl:rounded-lg xl:py-10 xl:mb-4" : "xl:py-16"
      )}
    >
      <div className={"flex justify-between items-center"}>
        <h2 className={"heading2 text-white"}>{t("home:editorialNotes.title")}</h2>
        <Button
          className={"whitespace-nowrap"}
          href={routes.editorialNotes.pathname}
          textColor={"primary-400"}
          bgColor={"white"}
          icon={<ChevronRightIcon className={"icon"} />}
          iconEnd
          small
        >
          {t("home:editorialNotes.seeAll")}
        </Button>
      </div>
      <div
        className={cx(
          "grid grid-cols-1 xl:grid-cols-2 pt-6 gap-y-2 xl:gap-4",
          isOnNewsfeed ? "xl:pt-4" : "xl:pt-8"
        )}
      >
        {editorialNotes.map((e, index) => (
          <EditorialNoteCard
            key={index}
            date={e.published_at}
            href={e.fileUrl?.url}
            title={e.title}
            content={e.content}
            className={cx(styles.card)}
            imageUrl={e.imageUrl}
            withPicture={true}
          />
        ))}
      </div>
    </section>
  );
};
